import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";

export default function CreateNotification() {
  const navigate = useNavigate();
  return (
    <Layout>
      <Container fluid>
        <div className="filters pb-2">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>Create Notification</h2>
            </div>
          </div>
          <div className="create-notification-page">
            <div className="category-field">
              <div className="input-select">
                <label>Select User</label>
                <Form.Select aria-label="Default select example">
                  <option> Select </option>
                  <option value="1">24</option>
                  <option value="2">25</option>
                  <option value="3">26</option>
                </Form.Select>
              </div>
              <div className="modal-input ">
                <label>Title</label>
                <Form.Group>
                  <Form.Control type="text" placeholder="Type here " />
                </Form.Group>
              </div>
            </div>
            <div className="textarea-input">
              <Form.Group className="mt-4">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" rows={4} placeholder="Type here" />
              </Form.Group>
            </div>
            <div className="create-btn">
              <button type="submit">Create</button>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="Codobux">
            <p>
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
