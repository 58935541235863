import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";

export default function ChatBox() {
  const navigate = useNavigate();
  return (
    <Layout>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>Jordan Stevenson</h2>
            </div>
            <div className="support-chat-box-main">
              <div className="support-inner">
                <div className="support-msg-box">
                  <div className="user-box">
                    <div className="user-top-hdng">
                      <img src={require("../Assets/Images/user.png")} />
                      <h3>name</h3>
                      <h4>08:13 pm</h4>
                    </div>
                    <div className="user-msg-box">
                      <p>Hey I'm John Smith</p>
                    </div>
                  </div>
                </div>
              </div>
              <form>
                <div class="send-box">
                  <div class="mb-3 input-group">
                    <input
                      placeholder="Type your message here..."
                      aria-label="send"
                      aria-describedby="basic-addon2"
                      name="message"
                      type="text"
                      class="send-feild form-control"
                      value=""
                    />
                    <button
                      class="input-group-text"
                      id="basic-addon2"
                      type="submit"
                    >
                      Send
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M6.66406 9.33333L13.9974 2"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M14.003 2L9.66964 14C9.60991 14.1303 9.47969 14.2139 9.33631 14.2139C9.19293 14.2139 9.06271 14.1303 9.00298 14L6.66964 9.33333L2.00298 7C1.87263 6.94027 1.78906 6.81004 1.78906 6.66667C1.78906 6.52329 1.87263 6.39306 2.00298 6.33333L14.003 2"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="Codobux">
            <p>
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
