import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Layout from "../Components/Layout/Layout";

export default function ReportDetails() {
  const navigate = useNavigate();
  return (
    <Layout>
      <Container fluid>
        <div className="filters pb-2">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>Report View</h2>
            </div>
          </div>
          <div className="create-notification-page">
            <div className="category-field">
              <div className="modal-input ">
                <label>Sent By</label>
                <Form.Group>
                  <Form.Control type="text" placeholder="Type here " />
                </Form.Group>
              </div>
              <div className="modal-input ">
                <label>Reported Against</label>
                <Form.Group>
                  <Form.Control type="text" placeholder="Type here " />
                </Form.Group>
              </div>
            </div>

            <div className="modal-input mt-4 ">
              <label>Reason</label>
              <Form.Group>
                <Form.Control type="text" placeholder="Type here " />
              </Form.Group>
            </div>
            <div className="textarea-input">
              <Form.Group className="mt-4">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" rows={4} placeholder="Type here" />
              </Form.Group>
            </div>
            {/* <div className="create-btn">
              <button type="submit">Create</button>
            </div> */}
          </div>
        </div>
        <div className="made-with">
          <div className="Codobux">
            <p>
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
