import { createSlice } from "@reduxjs/toolkit";
import { Userlisting } from "../Actions/AdminAction";


export const usermanageSlice = createSlice({
    name: "usermanageSlice",
    initialState: {
        alldata: "",
        loading: false,
        error: null,
    },
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(Userlisting.pending, (state) => {
                state.loading = true; 
                state.error = null;  
            })
            .addCase(Userlisting.fulfilled, (state, action) => {
                state.loading = false; 
                state.alldata = action.payload.data; 
            })
            .addCase(Userlisting.rejected, (state, action) => {
                state.loading = false; 
                state.error = action.error.message; 
            });
    },
});

export default usermanageSlice.reducer;
