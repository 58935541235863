import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { DeleteInterest, InterestListing } from "../Redux/Actions/AdminAction";
import PaginationComponet from "../Components/Layout/Pagination";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

export default function Interest() {
  const interestdata = useSelector((state) => state.intrst?.alldata);
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [showd, setshowd] = useState(false);
  // const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [flag, setflag] = useState(true);
  const [interestId, setIntrestId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch(
      InterestListing({
        page: pageNumber,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    const searchData = {
      page: currentPage,
      limit: limit,
      search: search,
    };
    dispatch(InterestListing(searchData));
  }, [currentPage, limit, search, flag]);

  const handleDelete = () => {
    dispatch(DeleteInterest({ id: interestId }))
      .then((res) => {
        console.log(res);
        if (res?.payload?.data?.status === 200) {
          toast?.success(res?.payload?.data?.message);
          setshowd(false);
          setflag(!flag);
        }
      })
      .catch((err) => {
        console.log(err, "err Occur");
      });
  };

  return (
    <Layout>
      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="image-heading-text add-interest-btn">
              <h2>Interest</h2>
              <div className="create-new-btn">
                <button onClick={() => navigate("/add-interest")}>
                  Add Interest
                </button>
              </div>
            </div>
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select
                    aria-label="Default select example"
                    name="limit"
                    onChange={(e) => setlimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control
                        type="text"
                        placeholder="Search interest..."
                        name="search"
                        onChange={(e) => setsearch(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.no.</th>
                    <th>Icon</th>
                    <th>Title</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {interestdata?.data?.interests?.map((res, index) => {
                    const dynamicIndex =
                      (currentPage - 1) * limit + (index + 1);
                    return (
                      <tr key={index}>
                        <td>
                          {" "}
                          <div className="cont-num"> {dynamicIndex}</div>
                        </td>

                        <td>
                          <div className="interest-icons">
                            <p>
                              {res?.image ? (
                                <img
                                  src={`${url}public/uploads/${res?.image}`}
                                />
                              ) : null}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="user-role">
                            <p>{res?.name || "N/A"}</p>
                          </div>
                        </td>


                        <td>
                          <div className="pending delete-btn">
                            <p
                              onClick={() => {
                                setshowd(true);
                                setIntrestId(res?._id);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              Delete
                            </p>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    {" "}
                    Showing {1 + (currentPage - 1) * limit} -{" "}
                    {Math.min(
                      currentPage * limit,
                      interestdata?.data?.totalInterest
                    )}{" "}
                    of {interestdata?.data?.totalInterest} results
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponet
                    currentPage={currentPage}
                    totalCount={interestdata?.data?.totalInterest}
                    limit={interestdata?.data?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="Codobux">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>

      <Modal show={showd} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to delete this Interest?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn conform-btn" onClick={handleDelete}>
            Yes
          </Button>
          <Button
            className="theme-btn cancel-btn"
            onClick={() => setshowd(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
