// // import React, { useState } from "react";
// // import { Link, useNavigate } from "react-router-dom";
// // import Container from "react-bootstrap/Container";
// // import Row from "react-bootstrap/Row";
// // import Col from "react-bootstrap/Col";
// // import Form from "react-bootstrap/Form";
// // import Table from "react-bootstrap/Table";
// // import Layout from "../Components/Layout/Layout";
// // import Dropdown from "react-bootstrap/Dropdown";
// // import Pagination from "react-bootstrap/Pagination";

// // export default function AddInterest() {
// //   const navigate = useNavigate();
// //   return (
// //     <Layout>
// //       <Container fluid>
// //         <div className="filters pb-2">
// //           <div className="inner-filter-field">
// //             <div className="image-heading-text">
// //               <h2>Add Interest</h2>
// //             </div>
// //           </div>
// //           <div className="create-notification-page div-size">
// //             <div className="category-field">
// //               <div className="modal-input ">
// //                 <label>Title</label>
// //                 <Form.Group>
// //                   <Form.Control type="text" placeholder="Type here " />
// //                 </Form.Group>
// //               </div>
// //             </div>
// //             <div className="add-new-photo mt-4">
// //               <Form.Group className="mb-3">
// //                 <Form.Label>Add Picture</Form.Label>
// //                 <div className="add-photo">
// //                   <Form.Control
// //                     type="file"

// //                   />

// //                   <span>
// //                     <svg
// //                       xmlns="http://www.w3.org/2000/svg"
// //                       width="74"
// //                       height="70"
// //                       viewBox="0 0 74 70"
// //                       fill="none"
// //                     >
// //                       <g opacity="0.3">
// //                         <path
// //                           d="M5.75248 52.9423C8.8635 49.8307 11.7839 46.9049 14.7086 43.9835C15.0032 43.6896 15.2849 43.3871 15.6055 43.1278C17.135 41.8658 19.1628 41.8788 20.6273 43.2185C21.7799 44.2773 22.8631 45.4139 23.9767 46.516C25.1769 47.7044 26.1301 47.7131 27.3217 46.5246C33.2707 40.5996 39.2111 34.6702 45.1602 28.7409C47.383 26.5282 49.3891 26.5109 51.5946 28.7106C56.872 33.9701 62.1495 39.2296 67.4183 44.4934C67.648 44.7225 67.8083 45.012 68.0033 45.2756C68.0986 45.2281 68.1939 45.1849 68.2892 45.1373C68.2892 44.8348 68.2892 44.5323 68.2892 44.2298C68.2892 40.6298 68.2849 37.0255 68.2892 33.4256C68.2936 31.524 69.4158 30.2707 71.1186 30.2664C72.8128 30.2621 73.9697 31.524 73.974 33.404C73.9783 40.6946 73.9567 47.9897 73.9913 55.2803C74 57.2035 74.0823 59.105 73.493 60.9763C71.8032 66.3655 66.8897 70.0043 61.2309 70C45.8361 69.9827 30.4457 69.9697 15.0509 69.9308C13.3437 69.9265 11.5889 69.987 9.93806 69.6327C3.76801 68.2929 -0.356908 62.7007 0.0243873 56.4385C0.0460518 56.1144 0.0677163 55.7903 0.0677163 55.4662C0.0677163 43.0629 0.0850479 30.664 0.0503847 18.2651C0.0460518 16.3376 -0.00594305 14.4404 0.59633 12.5734C2.28616 7.33124 7.18234 3.71399 12.7068 3.71399C21.9445 3.71399 31.1779 3.75289 40.4157 3.76153C41.7849 3.76153 42.8421 4.24124 43.4184 5.5291C43.869 6.53173 43.6264 7.76341 42.8551 8.57157C42.1662 9.28897 41.2996 9.44455 40.342 9.44455C31.8755 9.43158 23.4091 9.4359 14.9426 9.44023C14.2623 9.44023 13.582 9.46183 12.9018 9.52666C8.11825 9.96747 5.93446 12.2839 5.76981 17.081C5.75248 17.552 5.75681 18.0274 5.75681 18.4985C5.75681 29.5706 5.75681 40.6385 5.75681 51.7106C5.75248 52.0261 5.75248 52.3416 5.75248 52.9423Z"
// //                           fill="black"
// //                           fill-opacity="0.5"
// //                         />
// //                         <path
// //                           d="M66.3654 13.2908C66.3654 14.9417 66.3958 16.5278 66.3568 18.1095C66.3264 19.3455 65.4425 20.3871 64.29 20.6896C63.1201 20.9964 61.7942 20.5513 61.2093 19.4881C60.8973 18.922 60.75 18.2132 60.7197 17.5607C60.646 16.1561 60.698 14.7473 60.698 13.239C59.1295 13.239 57.6606 13.2563 56.1918 13.2347C54.4889 13.2087 53.2627 12.1326 53.1631 10.6244C53.0548 8.94323 54.203 7.63808 55.8971 7.58622C57.431 7.53868 58.9692 7.54733 60.5073 7.53004C60.5333 7.53004 60.555 7.49979 60.7023 7.39607C60.7023 6.4064 60.6937 5.32166 60.7067 4.24124C60.7153 3.59299 60.685 2.93609 60.7977 2.30512C61.0533 0.844394 62.3532 -0.110698 63.809 0.0103092C65.2085 0.131316 66.3221 1.26792 66.3741 2.70704C66.4174 3.97329 66.3871 5.24387 66.3914 6.51012C66.3914 6.82561 66.3914 7.14109 66.3914 7.58622C68.1116 7.58622 69.7278 7.55597 71.3439 7.59487C72.5225 7.62512 73.4584 8.3987 73.8353 9.53098C74.2036 10.6244 73.909 11.8906 72.9341 12.5346C72.3535 12.9192 71.5952 13.1482 70.8933 13.2131C69.7191 13.3211 68.5319 13.239 67.349 13.2433C67.0457 13.2476 66.7554 13.2736 66.3654 13.2908Z"
// //                           fill="black"
// //                           fill-opacity="0.5"
// //                         />
// //                         <path
// //                           d="M26.5721 25.5169C26.5764 28.1445 24.4663 30.2664 21.8492 30.2707C19.2451 30.2751 17.1133 28.1402 17.109 25.5299C17.1047 22.9196 19.2278 20.7846 21.8362 20.7803C24.4533 20.7717 26.5721 22.8893 26.5721 25.5169Z"
// //                           fill="black"
// //                           fill-opacity="0.5"
// //                         />
// //                       </g>
// //                     </svg>
// //                   </span>
// //                 </div>

// //               </Form.Group>
// //             </div>
// //             <div className="create-btn">
// //               <button type="submit">Create</button>
// //             </div>
// //           </div>
// //         </div>
// //         <div className="made-with">
// //           <div className="Codobux">
// //             <p>
// //               © 2024, Made with ❤️ by <span>Codobux</span>
// //             </p>
// //           </div>
// //         </div>
// //       </Container>
// //     </Layout>
// //   );
// // }

// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import Container from "react-bootstrap/Container";
// import Form from "react-bootstrap/Form";
// import Layout from "../Components/Layout/Layout";
// import * as Yup from "yup";
// import { Formik, Field } from "formik";
// import { useDispatch } from "react-redux";
// import { addinterests } from "../Redux/Actions/AdminAction";
// import { toast } from "react-toastify";

// const validationSchema = Yup.object({
//   name: Yup.string().required("Title is required"),
//   image: Yup.mixed().required("Picture is required"),
// });

// export default function AddInterest() {

//   const url = process.env.REACT_APP_FILE_BASE_URL;

//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   const handleSubmit = (values) => {

//     const formData = new FormData();
//     formData.append("name", values.name);
//     if (values.image) {
//       formData.append("image", values.image);
//     }

//     dispatch(addinterests(formData)).then((res) => {

//       console.log(res);
//       if(res?.payload?.status == 200){
//         toast.success(res?.payload?.data?.message);
//        navigate('/interest')
//       }
//     }).catch((error) => {
//       console.log(error);
//     })
//   };

//   return (
//     <Layout>
//       <Container fluid>
//         <div className="filters pb-2">
//           <div className="inner-filter-field">
//             <div className="image-heading-text">
//               <h2>Add Interest</h2>
//             </div>
//           </div>
//           <Formik
//             initialValues={{ name: "", image: null }}
//             validationSchema={validationSchema}
//             onSubmit={handleSubmit}

//           >
//             {({setFieldValue,handleSubmit, errors, touched }) => (

//               <Form onSubmit={handleSubmit} className="create-notification-page div-size">
//                 <div className="category-field">
//                   <div className="modal-input">
//                     <label>Title</label>
//                     <Field
//                       name="name"
//                       type="text"
//                       placeholder="Type here"
//                       className="form-control"
//                     />
//                     {errors.name && touched.name && (
//                       <div className="text-danger">{errors.name}</div>
//                     )}
//                   </div>
//                 </div>
//                 <div className="add-new-photo mt-4">
//                   <Form.Group className="mb-3">
//                     <Form.Label>Add Picture</Form.Label>
//                     <div className="add-photo">
//                       <input
//                         type="file"
//                         name="image"
//                         onChange={(event) => {
//                           setFieldValue("image", event.currentTarget.files[0]);
//                         }}
//                         className="form-control"

//                       />
//                       {errors.image && touched.image && (
//                         <div className="text-danger">{errors.image}</div>
//                       )}
//                     </div>
//                   </Form.Group>
//                 </div>
//                 <div className="create-btn">
//                   <button type="submit">Create</button>
//                 </div>
//               </Form>
//             )}
//           </Formik>
//         </div>
//       </Container>
//     </Layout>
//   );
// }

////start

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Layout from "../Components/Layout/Layout";
import * as Yup from "yup";
import { Formik, Field } from "formik";
import { useDispatch } from "react-redux";
import { addinterests } from "../Redux/Actions/AdminAction";
import { toast } from "react-toastify";

const validationSchema = Yup.object({
  name: Yup.string().required("Title is required"),
  image: Yup.mixed().required("Picture is required"),
});

export default function AddInterest() {
  const url = process.env.REACT_APP_FILE_BASE_URL;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [imagePreview, setImagePreview] = useState(null); 
  console.log(imagePreview,"haiga kuch k nahi");

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    if (values.image) {
      formData.append("image", values.image);
    }

    dispatch(addinterests(formData))
      .then((res) => {
        console.log(res);
        if (res?.payload?.status == 200) {
          toast.success(res?.payload?.data?.message);
          navigate("/interest");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Layout>
      <Container fluid>
        <div className="filters pb-2">
          <div className="inner-filter-field">
            <div className="image-heading-text">
              <h2>Add Interest</h2>
            </div>
          </div>
          <Formik
            initialValues={{ name: "", image: null }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, handleSubmit, errors, touched }) => (
              <Form
                onSubmit={handleSubmit}
                className="create-notification-page div-size"
              >
                <div className="category-field">
                  <div className="modal-input">
                    <label>Title</label>
                    <Field
                      name="name"
                      type="text"
                      placeholder="Type here"
                      className="form-control"
                    />
                    {errors.name && touched.name && (
                      <div className="text-danger">{errors.name}</div>
                    )}
                  </div>
                </div>
                <div className="add-new-photo mt-4">
                  <Form.Group className="mb-3">
                    <Form.Label>Add Picture</Form.Label>
                    <div className="add-photo">
                      {imagePreview ? null : "Upload picture"}
                      <input
                        type="file"
                        name="image"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          setFieldValue("image", file);
                          setImagePreview(URL.createObjectURL(file));
                        }}
                        className="form-control"
                      />
                      {errors.image && touched.image && (
                        <div className="text-danger">{errors.image}</div>
                      )}

                      {imagePreview && (
                        <div className="image-preview mt-2">
                          <img
                            src={imagePreview}
                            alt="Image preview"
                            style={{
                              maxWidth: "100px",
                              maxHeight: "100px",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Form.Group>
                </div>
                <div className="create-btn">
                  <button type="submit">Create</button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </Layout>
  );
}
