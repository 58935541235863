import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Nonverifyusers, VerifyUnverifyUser } from "../Redux/Actions/AdminAction";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

function ModalPreview({ userId, Action, modal,setModalview, setflag, flag }) {

  const dispatch = useDispatch();
  const ActionHandler = () => {
    let payload = {id:userId, is_verify:Action == 'verify' ? 1 : 2}
    dispatch(VerifyUnverifyUser(payload)).then((res) => {
        if (res?.payload?.data?.status ==200) {
            toast?.success(res?.payload?.data?.message);
            setModalview(false);
            setflag(!flag)
          }
    }).catch((error) => {
        console.log(error);
    })
  }


  return (
    <div>
      <Modal show={modal} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to {Action} this user?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn conform-btn" onClick={ActionHandler}>
            Yes
          </Button>
          <Button
            className="theme-btn cancel-btn"
            onClick={() => setModalview(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalPreview;
