import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../Services/AdminApi";


// Login

const login = createAsyncThunk(
    "login", 
    async(details) => {
        const { data } = await AdminAPI.post('/adminLogin', details); 
        console.log(data,"datadata");    
        return data;
    }
);

export { login };


// Otp verifiction 

export const  Otpverify = createAsyncThunk(
    "Otpverify",
    async(details)=> {
       const {data} = await AdminAPI.post(`/verify_otp`, details)
       return data;
    }
  );

// Forgot password

export const  forgotPass = createAsyncThunk(
    "forgotPass",
    async(details)=> {
       const {data} = await AdminAPI.post(`/forgot_password`, details)
       return data;
    }
  );

  // reset password

export const resetpass = createAsyncThunk(
    "resetpass",
    async(details)=> {
       const {data} = await AdminAPI.post(`/change_password`, details)
       return data;
    }
  );
