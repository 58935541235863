import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getdashboard } from "../Redux/Actions/AdminAction";

function CommonDashboard() {
  const[dashcount,setDashCount]=useState({})
  const dispatch = useDispatch();

  useEffect(() => {
    const getdata = async() => {
      try {
        const data = await dispatch(getdashboard());
        console.log(data);
        if(data?.payload?.data?.status == 200){
          setDashCount(data?.payload?.data?.data)
        }
      } catch (error) {
        console.log(error);
      }
    };
    getdata();
  }, [dispatch]);

  return (
    <div className="dashboard-items">
      <Row>
        <Col xxl={12} xl={12} lg={12}>
          <div className="dashbox-inner-wrap">
            <Row>
              <Col xxl={4} xl={4} lg={4}>
                <div className="dash-inner-boxes">
                  <div className="session">
                    <div className="left-session">
                      <p>Total Users</p>
                      <h2>{dashcount?.total_users || "00"}</h2>
                      {/* <span className="total-users">Total Users</span> */}
                    </div>
                    <div className="user-icon-se">
                      <img
                        src={
                          require("../Assets/Images/session-user.svg").default
                        }
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col xxl={4} xl={4} lg={4}>
                <div className="dash-inner-boxes">
                  <div className="session">
                    <div className="left-session">
                      <p>Verified Users </p>
                      <h2>{dashcount?.verified_users || "00"}</h2>
                    </div>
                    <div className="user-icon-se">
                      <img
                        src={require("../Assets/Images/padi-user.svg").default}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col xxl={4} xl={4} lg={4}>
                <div className="dash-inner-boxes">
                  <div className="session">
                    <div className="left-session">
                      <p>Active Users</p>
                      <h2>{dashcount?.Active_users || "00"}</h2>
                      {/* <span className="total-users">
                            Last week analytics
                          </span> */}
                    </div>
                    <div className="user-icon-se">
                      <img
                        src={
                          require("../Assets/Images/active-user.svg").default
                        }
                      />
                    </div>
                  </div>
                </div>
              </Col>
              {/* <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Pending Service providers</p>
                          <h2>00</h2>
                          <span className="total-users">
                            Last week analytics
                          </span>
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/pending-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col> */}
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CommonDashboard;
