import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useState } from "react";
import OtpInput from "react-otp-input";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { Otpverify } from "../Redux/Actions/AdminAuth";
import { Resendotp } from "../Redux/Actions/AdminAction";

const validationSchema = Yup.object({
  otp: Yup.string()
    .length(6, "OTP must be exactly 6 digits")
    .matches(/^[0-9]+$/, "OTP must be numeric")
    .required("OTP is required"),
});

export default function OtpVarification() {
  const adminid = localStorage.getItem("id");
  const [countdown, setCountdown] = useState(30);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    const payload = {
      id: adminid,
      otp: values.otp,
    };

    dispatch(Otpverify(payload))
      .then((res) => {
        console.log(res);
        if (res?.payload?.status === 200) {
          toast.success(res?.payload?.message);
          setTimeout(() => {
            toast.dismiss();
            window.location.href = "/user";
          }, 1500);
          sessionStorage.setItem("token", res?.payload?.data?.token);
        } else {
          toast.error(res?.payload?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleResendOtp = (setFieldValue) => {
    dispatch(Resendotp({ id: adminid }))
      .then((res) => {
        if (res?.payload?.data?.status === 200) {
          toast.success(res?.payload?.data?.message);
          setCountdown(30);
          startCountdown();
          setFieldValue("otp", "");
        } else {
          toast.error(res?.payload?.data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const startCountdown = () => {
    setIsResendDisabled(true);
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          setIsResendDisabled(false);
          return 30;
        }
        return prev - 1;
      });
    }, 1000);
  };

  useEffect(() => {
    startCountdown();
    return () => clearInterval();
  }, []);

  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="">
        <div className="row  sign-banner-part">
          <Col lg={8} className="p-0">
            <div className="new-otp-banner-img"></div>
          </Col>
          <Col lg={4}>
            <div className="right-banner-part">
              <div className="login-cmn-box">
                <div className="login-box-inner-wrap">
                  <h2>Two Step Verification 💬</h2>
                  <p className="mb-0">
                    We sent a verification code to your Email. Enter the code
                    from the Email in the field below.
                    <br />
                    {/* <span>******1234</span> */}
                  </p>
                  <Formik
                    initialValues={{
                      otp: "", // OTP field initial value
                    }}
                    validationSchema={validationSchema} // Yup validation schema
                    onSubmit={(value) => handleSubmit(value)} // Submit function
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      values,
                      setFieldValue,
                    }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="form-set set-otp">
                          <OtpInput
                            value={values.otp}
                            onChange={(value) => setFieldValue("otp", value)}
                            name="otp"
                            numInputs={6}
                            renderInput={(props) => <input {...props} />}
                          />
                          <ErrorMessage
                            className="error-message"
                            name="otp"
                            component="div"
                          />
                        </div>
                        <button
                          variant="primary"
                          type="submit"
                          className="submit forgot-btn"
                        >
                          Verify my account
                        </button>
                        <div className="resend-mail">
                          {isResendDisabled ? (
                            <div className="">
                              <p className="">
                                Resend OTP in{" "}
                                <span className="">{`00:${String(
                                  countdown
                                ).padStart(2, "0")}s`}</span>
                              </p>
                            </div>
                          ) : (
                            <span
                              style={{
                                textDecoration: "none",
                                color: "#444151",
                              }}
                            >
                            
                            Didn't get the mail?
                            
                              <a
                                style={{
                                  textDecoration: "none",
                                  color: "black",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleResendOtp(setFieldValue)}
                                className="resend-button"
                              >
                                Resend OTP
                              </a>
                            </span>
                          )}
                          {/* <p>
                            Didn't get the mail?   <span> Resend </span>
                          </p> */}
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Container>
    </div>
  );
}
