import { createSlice } from "@reduxjs/toolkit";
import { Nonverifyusers } from "../Actions/AdminAction";



export const nonverifyuserSlice = createSlice({
    name: "nonverifyuserSlice",
    initialState: {
        users: "",
        loading: false,
        error: null,
    },
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(Nonverifyusers.pending, (state) => {
                state.loading = true; 
                state.error = null;  
            })
            .addCase(Nonverifyusers.fulfilled, (state, action) => {
                state.loading = false; 
                state.users = action.payload.data; 
            })
            .addCase(Nonverifyusers.rejected, (state, action) => {
                state.loading = false; 
                state.error = action.error.message; 
            });
    },
});

export default nonverifyuserSlice.reducer;
