import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Layout from "../Components/Layout/Layout";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import {
  DeleteUser,
  Suspenduser,
  getuserprofile,
} from "../Redux/Actions/AdminAction";
import { Button, Modal, Placeholder } from "react-bootstrap";
import placeholder from "../Assets/Images/admin_Placeholder.png";
import { toast } from "react-toastify";

export default function UserVerificationProfileView() {
  const navigate = useNavigate();

  const url = process.env.REACT_APP_FILE_BASE_URL;

  const [UserDetails, setuserDetails] = useState("");
  const [ActivityDetails, setActivityDetails] = useState([]);
  const [showmodal, setShowModal] = useState(false);
  const [type, setType] = useState("");
  const [flag, setflag] = useState(true);

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getuserprofile({ userId: id }))
      .then((response) => {
        console.log(response, "User data");
        if (response?.payload?.data?.status === "200") {
          setuserDetails(response?.payload?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error, "Error occur");
      });
  }, []);

  const handleActiveSuspend = async () => {
    try {
      let response;
      if (type === "suspend") {
        const payload = { is_suspend: "1", id: id };
        response = await dispatch(Suspenduser(payload));
      } else if (type === "delete") {
        response = await dispatch(DeleteUser({ id }));
      }
      if (response?.payload?.data?.status == 200) {
        toast?.success(response?.payload?.data?.message);
        setShowModal(false);
        navigate("/user");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <Layout>
      <Container fluid>
        <div className="image-wapper">
          <div className="user-over-view">
            <div className="left-side-over-view">
              <div className="inner-over-box">
                <div className="my-overview-prof">
                  {UserDetails?.selfie_image &&
                  UserDetails?.selfie_image !== null &&
                  UserDetails?.selfie_image !== "" ? (
                    <img
                      src={`${url}public/uploads/${UserDetails?.selfie_image}`}
                      alt="Profile"
                      // onClick={() =>
                      //   handlePrivewImagesOpen(UserDetails?.selfie_image)
                      // }
                    />
                  ) : (
                    <img src={placeholder} />
                  )}
                  <h2>{UserDetails?.name || "N/A"}</h2>
                </div>

                <div className="details-heading-text">
                  <h2>Details</h2>
                  <div className="user-full-details">
                    <ul>
                      <li>
                        <p>
                          Email: <span>{UserDetails?.email || "N/A"}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Contact:{" "}
                          <span>
                            {UserDetails?.phone_number
                              ? UserDetails.country_code
                                ? `${UserDetails.country_code} ${UserDetails.phone_number}`
                                : `${UserDetails.phone_number}`
                              : "N/A"}
                          </span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-side-over-view">
              <div className="inner-box-images">
                <div className="outer-shadow-box mt-0">
                  <div className="grid-over-view">
                    <div className="inner-filter-field">
                      <div className="image-heading-text">
                        <h2>Profile Info</h2>
                      </div>
                      <div className="booking-info">
                        <div className="booking-grid">
                          <div className="booking-label">
                            <label>Date of birth</label>
                            <p>{UserDetails?.dob || "N/A"}</p>
                          </div>
                          <div className="booking-label">
                            <label>Height</label>
                            <p>{UserDetails?.height || "N/A"}</p>
                          </div>

                          <div className="booking-label">
                            <label>Gender </label>
                            <p>{UserDetails?.gender || "N/A"}</p>
                          </div>
                          <div className="booking-label">
                            <label>Occupation</label>
                            <p>{UserDetails?.occupation || "N/A"}</p>
                          </div>
                        </div>

                        <div className="booking-label bio-field">
                          <label>Bio</label>
                          <p>{UserDetails?.about || "N/A"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="made-with">
          <div className="Codobux">
            <p>
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>

      <Modal show={showmodal} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to {type === "delete" ? "Delete" : "Suspend"}{" "}
            this Account?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button
            className="theme-btn conform-btn"
            onClick={handleActiveSuspend}
          >
            Yes
          </Button>
          <Button
            className="theme-btn cancel-btn"
            onClick={() => setShowModal(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
