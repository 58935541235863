import { configureStore } from "@reduxjs/toolkit";
import { usermanageSlice } from "../Reducer/UsermgmtSlice";
import { interestSlice } from "../Reducer/InterestSlice";
import { nonverifyuserSlice } from "../Reducer/UserVrificationSlice";


export const Store = configureStore({
  reducer: {
      users:usermanageSlice.reducer,
      intrst:interestSlice.reducer,
      nonverifyuser:nonverifyuserSlice.reducer
  },
});
