import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import CommonDashboard from "../Common/CommonDashboard";
import { useDispatch, useSelector } from "react-redux";
import placeholder from "../Assets/Images/admin_Placeholder.png";
import {
  DowanloadNonverify,
  Nonverifyusers,
} from "../Redux/Actions/AdminAction";
import * as XLSX from "xlsx";
import PaginationComponet from "../Components/Layout/Pagination";
import ModalPreview from "../Common/ModalPreview";

export default function UserVerification() {
  const userdata = useSelector((state) => state.nonverifyuser?.users);
  console.log(userdata, "userdata");
  const url = process.env.REACT_APP_FILE_BASE_URL;
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [flag, setflag] = useState(true);
  const [userid, setUserid] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [actiontype, setActionType] = useState("");
  const [modalview, setModalview] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    dispatch(
      Nonverifyusers({
        page: pageNumber,
        limit: limit,
      })
    );
  };

  useEffect(() => {
    const searchData = {
      page: currentPage,
      limit: limit,
      search: search,
    };
    dispatch(Nonverifyusers(searchData));
  }, [currentPage, limit, search, flag]);

  const fetchDataAndExportToExcel = useCallback(async () => {
    try {
      const response = await dispatch(DowanloadNonverify());
      console.log(response);

      const allData = response?.payload?.data?.data?.users;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  }, [dispatch]);

  return (
    <Layout>
      <Container fluid>
        <CommonDashboard />
      </Container>

      <Container fluid>
        <div className="filters">
          <div className="inner-filter-field">
            <div className="user-search">
              <div className="drop-down">
                <div className="select-box">
                  <Form.Select
                    aria-label="Default select example"
                    name="limit"
                    onChange={(e) => setlimit(e.target.value)}
                  >
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="25">25</option>
                  </Form.Select>
                </div>
              </div>
              <div className="search-tab">
                <div className="input-search">
                  <Form>
                    <Form.Group controlId="formBasicPassword">
                      <Form.Control
                        type="text"
                        placeholder="Search User"
                        name="search"
                        onChange={(e) => setsearch(e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </div>
                <div className="export-box" onClick={fetchDataAndExportToExcel}>
                  <p>
                    <span>
                      <img
                        src={require("../Assets/Images/upload.svg").default}
                      />
                    </span>
                    Export
                  </p>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <Table size="sm" className="table-cmn">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>
                      <div className="user-checkbox">User</div>
                    </th>
                    <th>Role</th>
                    <th>Contact</th>
                    <th>Email </th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {userdata?.data?.users?.map((res, index) => {
                    const dynamicIndex =
                      (currentPage - 1) * limit + (index + 1);
                    return (
                      <tr key={index}>
                        <td>
                          {" "}
                          <div className="cont-num"> {dynamicIndex}</div>
                        </td>
                        <td>
                          <div className="first-user">
                            <div className="user-profile">
                              <img
                                src={
                                  res?.selfie_image
                                    ? `${url}public/uploads/${res?.selfie_image}`
                                    : placeholder
                                }
                                style={{ borderRadius: 50 }}
                              />
                              <div
                                className="user-id user-fontweight"
                                onClick={() =>
                                  navigate("/user-verification-profile-view")
                                }
                                style={{cursor:"pointer" }}
                              >
                                <p>{res?.name || "N/A"}</p>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="user-role">
                            <p>
                              <span>
                                <img
                                  src={
                                    require("../Assets/Images/table-user.svg")
                                      .default
                                  }
                                />
                              </span>
                              User
                            </p>
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div className="cont-num">
                            {" "}
                            {res?.phone_number
                              ? res.country_code
                                ? `${res.country_code} ${res.phone_number}`
                                : `${res.phone_number}`
                              : "N/A"}{" "}
                          </div>
                        </td>
                        <td>
                          <div className="debit">{res?.email || "N/A"}</div>
                        </td>
                        <td>
                          <div
                            className={
                              res?.is_verify == 0 ? "pending" : "rejected"
                            }
                          >
                            <p>
                              {res?.is_verify == 0 ? "Pending" : "Rejected"}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div className="action user-verification">
                            <img
                              src={
                                require("../Assets/Images/check.svg").default
                              }
                              onClick={() => {
                                setModalview(true);
                                setUserid(res?._id);
                                setActionType("verify");
                              }}
                            />

                            <img
                              src={
                                require("../Assets/Images/cross.svg").default
                              }
                              onClick={() => {
                                setModalview(true);
                                setUserid(res?._id);
                                setActionType("unverify");
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="pagination-section">
                <div className="showing-user">
                  <p>
                    {" "}
                    <p>
                      Showing {1 + ((currentPage || 1) - 1) * (limit || 10)} -{" "}
                      {Math.min(
                        (currentPage || 1) * (limit || 10),
                        userdata?.data?.totalUsers || 0
                      )}{" "}
                      of {userdata?.data?.totalUsers || 0} results
                    </p>
                  </p>
                </div>
                <div className="pagination-block">
                  <PaginationComponet
                    currentPage={currentPage}
                    totalCount={userdata?.data?.totalUsers}
                    limit={userdata?.data?.totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              {" "}
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>
      <ModalPreview
        userId={userid}
        Action={actiontype}
        modal={modalview}
        setModalview={setModalview}
        setflag={setflag}
        flag={flag}
      />
    </Layout>
  );
}
